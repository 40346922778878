import { Route, Routes } from 'react-router-dom'
import TopBar from './component/TopBar'
import Home from './feature/home/Home'
import Curriculum from './feature/curriculum/Curriculum'
import Content from './feature/content/Content'
import Faq from './feature/faq/Faq'
import PcVersion from './feature/pcversion/PcVersion'
import Purchase from './feature/purchase/Purchase'
import AiCourseware from './feature/aicourseware/AiCourseware'

function App () {
  return (
    <div className="App">
      <TopBar/>
      <Routes>
        <Route index element={<Home/>}></Route>
        <Route path="/home" element={<Home/>}></Route>
        <Route path="/curriculum" element={<Curriculum/>}></Route>
        <Route path="/content/*" element={<Content/>}></Route>
        <Route path="/faq/*" element={<Faq/>}></Route>
        <Route path="/pc-version" element={<PcVersion/>}></Route>
        <Route path="/ai-courseware" element={<AiCourseware/>}></Route>
        <Route path="/purchase" element={<Purchase/>}></Route>
      </Routes>
    </div>
  )
}

export default App
